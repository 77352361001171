
import { Options, Vue } from "vue-class-component";
import ChatScript from "./ChatScript.vue";

@Options({
  components: {
    ChatScript,
  },
  props: {
    size: {
      type: String,
      default: "md",
    },
    showAllLink: {
      type: Boolean,
      default: false,
    },
    socials: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Footer extends Vue {
  size: "md" | "sm";
  showAllLink: boolean;
  socials: boolean;
  empty: boolean;

  get year() {
    return new Date().getFullYear();
  }

  get socialsList() {
    return [
      {
        href: "https://www.facebook.com/medsurvey",
        src: "facebook",
      },
      {
        href: "https://twitter.com/medsurvey",
        src: "twitter",
      },
    ];
  }

  get privacyPolicyLink() {
    return this.$router.resolve({ name: "PrivacyPolicy" }).href;
  }

  get surveyTermsLink() {
    return this.$router.resolve({ name: "SurveyTerms" }).href;
  }

  get links() {
    return [
      {
        href: `${this.surveyTermsLink}`,
        text: "Survey Terms",
        class: "footer__item_terms",
        hide: false,
      },
      {
        href: `${this.privacyPolicyLink}`,
        text: "Privacy Policy",
        class: "footer__item_terms",
        hide: false,
      },
      {
        href: "https://www.medsurvey.com/sites/main/",
        text: "Contact",
        class: "is-check",
        hide: !this.showAllLink,
      },
      {
        href: "#",
        text: "Sign In",
        class: "is-check",
        hide: !this.showAllLink,
      },
    ];
  }
}
