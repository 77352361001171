import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rewards-layout" }
const _hoisted_2 = { class: "rewards-layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      terms: _ctx.headerSettings.terms,
      onOnLogoClick: _ctx.toWelcome
    }, null, 8, ["terms", "onOnLogoClick"]),
    _createElementVNode("div", {
      class: _normalizeClass(["rewards-layout__in", [_ctx.layoutSettings.class]])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2),
    (_ctx.footerSettings.hidden !== true)
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 0,
          size: _ctx.footerSettings.size,
          showAllLink: _ctx.footerSettings.showAllLink,
          socials: _ctx.footerSettings.socials,
          terms: _ctx.footerSettings.terms
        }, null, 8, ["size", "showAllLink", "socials", "terms"]))
      : _createCommentVNode("", true)
  ]))
}