import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { h, resolveComponent } from "vue"

function page(path: string) {
  return () =>
    import(/* webpackChunkName: '' */ `../views/${path}`).then(
      (m) => m.default || m
    )
}

const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "home", redirect: { name: "Error" } },
  {
    path: "/welcome",
    name: "welcome",
    redirect: { name: "welcomeV2" }
  },
  {
    path: "/code",
    name: "code",
    redirect: { name: "PassCodeV2" }
  },
  {
    path: "/decline-confirm",
    name: "decline",
    // component: page("Decline"),
    redirect: { name: "declineIndividual" },
    meta: {
      title: "Decline"
    }
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    // component: page("Unsubscribe"),
    redirect: { name: "unsubscribeLogin" },
    meta: {
      title: "Unsubscribe"
    }
  },
  {
    path: "/exception",
    name: "Error",
    props: true,
    component: page("Error"),
    meta: {
      title: "Error"
    }
  },
  {
    path: "/survey/final",
    name: "SurveyStatus",
    // component: page("SurveyStatus"),
    redirect: { name: "AddressCorrectV2" }
  },
  {
    path: "/completed",
    name: "Completed",
    component: page("Completed"),
    meta: {
      title: "Completed"
    }
  },
  {
    path: "/terminated",
    name: "Terminated",
    component: page("Terminated"),
    meta: {
      title: "Terminated"
    }
  },
  {
    path: "/over-quota",
    name: "OverQuota",
    component: page("OverQuota"),
    meta: {
      title: "Over Quota"
    }
  },
  {
    path: "/vendor",
    name: "Vendor",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "download",
        name: "VendorDownload",
        component: page("Vendor/VendorDownload"),
        meta: {
          title: "File Share"
        }
      },
      {
        path: "upload",
        name: "VendorUpload",
        component: page("Vendor/VendorUpload"),
        meta: {
          title: "File Share"
        }
      }
    ]
  },
  {
    path: "/client",
    name: "Client",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "upload",
        name: "ClientUpload",
        component: page("Client/ClientUpload"),
        meta: {
          title: "File Share"
        }
      },
      {
        path: "download",
        name: "ClientDownload",
        component: page("Client/ClientDownload"),
        meta: {
          title: "File Share"
        }
      }
    ]
  },
  {
    path: "/rewards",
    component: {
      render() {
        return h(resolveComponent("router-view"))
      }
    },
    children: [
      {
        path: "unsubscribe",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "",
            name: "unsubscribeLogin",
            component: page("v2/unsubscribe/Login"),
            meta: {
              title: "Unsubscribe",
              layout: "rewards-layout"
            }
          },
          {
            path: "reasons",
            name: "unsubscribeReasons",
            component: page("v2/unsubscribe/Reasons"),
            meta: {
              title: "Unsubscribe Reasons",
              layout: "rewards-layout"
            }
          },
          {
            path: "cancel",
            name: "unsubscribeCancel",
            component: page("v2/unsubscribe/Cancel"),
            meta: {
              title: "Unsubscribe Cancel",
              layout: "rewards-layout"
            }
          }
        ]
      },
      {
        path: "unsubscribe-daily",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "",
            name: "unsubscribeDaily",
            component: page("v2/unsubscribe/UnsubscribeDaily"),
            meta: {
              title: "Unsubscribe",
              layout: "rewards-layout"
            }
          },
        ]
      },
      {
        path: "decline",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "",
            name: "declineIndividual",
            component: page("v2/decline/DeclineV2"),
            meta: {
              title: "Unsubscribe Individual",
              layout: "rewards-layout"
            }
          },
          {
            path: "confirm",
            name: "declineConfirm",
            component: page("v2/decline/DeclineConfirm"),
            meta: {
              title: "Unsubscribe Confirm",
              layout: "rewards-layout"
            }
          },
          {
            path: "cancel",
            name: "declineCancel",
            component: page("v2/decline/DeclineCancel"),
            meta: {
              title: "Unsubscribe Cancel",
              layout: "rewards-layout"
            }
          }
        ]
      },
      {
        path: "",
        redirect: { name: "welcomeV2" }
      },
      {
        path: "welcome",
        name: "welcomeV2",
        component: page("v2/Welcome"),
        meta: {
          title: "Welcome",
          layout: "rewards-layout"
        }
      },
      {
        path: "survey-disclaimer",
        name: "SurveyDisclaimerV2",
        component: page("v2/SurveyDisclaimer"),
        meta: {
          title: "Survey Disclaimer",
          layout: "rewards-layout",
          footerSettings: {
            size: "sm"
          }
        }
      },
      {
        path: "complete",
        name: "completeV2",
        component: page("v2/Completed"),
        meta: {
          layout: "rewards-layout",
          title: "Completed",
          headerSettings: {
            logoRedirect: true
          },
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true,
            chat: true
          }
        }
      },
      {
        path: "terminated",
        name: "terminatedV2",
        component: page("v2/Terminated"),
        meta: {
          layout: "rewards-layout",
          title: "Terminated",
          headerSettings: {
            logoRedirect: true
          },
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "all-finished",
        name: "allFinished",
        component: page("v2/AllFinished"),
        meta: {
          layout: "rewards-layout",
          title: "All Finished",
          headerSettings: {
            logoRedirect: true
          },
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "survey-resume",
        name: "surveyResume",
        component: page("v2/SurveyResume"),
        meta: {
          layout: "rewards-layout",
          title: "Resume",
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "redirect",
        name: "RedirectV2",
        component: page("v2/Redirected"),
        meta: {
          layout: "rewards-layout",
          title: "Redirect",
          footerSettings: {
            size: "md",
            socials: false,
            showAllLink: false
          }
        }
      },
      {
        path: "over-quota",
        name: "OverQuotaV2",
        component: page("v2/OverQuota"),
        meta: {
          layout: "rewards-layout",
          title: "Over Quota",
          headerSettings: {
            logoRedirect: true
          },
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "survey-paused",
        name: "SurveyPausedV2",
        component: page("v2/SurveyPaused"),
        meta: {
          layout: "rewards-layout",
          title: "Survey Paused",
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "survey-expired",
        name: "SurveyExpiredV2",
        component: page("v2/SurveyExpired"),
        meta: {
          layout: "rewards-layout",
          title: "Survey Expired",
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "decipher-logged-error",
        name: "DecipherLoggedError",
        component: page("v2/DecipherLoggedError"),
        meta: {
          layout: "rewards-layout",
          title: "Survey Data is missing",
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true
          }
        }
      },
      {
        path: "address-correct",
        name: "AddressCorrectV2",
        component: page("v2/AddressCorrect"),
        meta: {
          layout: "rewards-layout",
          title: "Address Correct",
          footerSettings: {
            size: "md",
            showAllLink: false,
            socials: false
          }
        }
      },
      {
        path: "address-edit",
        name: "AddressEditV2",
        component: page("v2/AddressEdit"),
        meta: {
          layout: "rewards-layout",
          title: "Address Edit",
          footerSettings: {
            size: "md",
            showAllLink: false,
            socials: false
          },
          layoutSettings: {
            class: "rewards-layout__in-baseline"
          }
        }
      },
      {
        path: "code",
        name: "PassCodeV2",
        component: page("v2/PassCode"),
        meta: {
          title: "Enter Passcode",
          layout: "rewards-layout"
        }
      },
      {
        path: "exception",
        name: "PortalError",
        component: page("v2/errors/ServerError"),
        meta: {
          title: "Oops! Something went wrong",
          layout: "rewards-layout",
          footerSettings: {
            hidden: true
          }
        }
      },
      {
        path: "expired",
        name: "ExpiredError",
        component: page("v2/errors/Expired"),
        meta: {
          layout: "rewards-layout",
          title: "Expired",
          headerSettings: {
            canLogoRedirect: false
          },
          footerSettings: {
            size: "md",
            showAllLink: true,
            socials: true,
            chat: true
          }
        }
      },
      {
        path: "/confirm",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        children: [
          {
            path: "",
            name: "ConfirmName",
            component: page("v2/ConfirmName"),
            meta: {
              layout: "rewards-layout",
              title: "Confirm Name",
              footerSettings: {
                size: "md",
                showAllLink: false,
                socials: false
              }
            }
          },
          {
            path: "email",
            name: "ConfirmEmail",
            component: page("v2/ConfirmEmail"),
            meta: {
              layout: "rewards-layout",
              title: "Confirm Email",
              footerSettings: {
                size: "md",
                showAllLink: false,
                socials: false
              }
            }
          },
          {
            path: "contact-information",
            name: "ContactInformation",
            component: page("v2/ContactInformation"),
            meta: {
              title: "Contact Information",
              layout: "rewards-layout"
            }
          }
        ]
      },
      {
        path: "privacy",
        name: "PrivacyPolicy",
        component: page("v2/PrivacyPolicy.vue"),
        meta: {
          title: "Privacy Policy",
          layout: "rewards-layout",
          headerSettings: {
            terms: true
          },
          footerSettings: {
            terms: true
          }
        }
      },
      {
        path: "survey-terms",
        name: "SurveyTerms",
        component: page("v2/SurveyTerms.vue"),
        meta: {
          title: "Survey Terms",
          layout: "rewards-layout",
          headerSettings: {
            terms: true
          },
          footerSettings: {
            terms: true
          }
        }
      },
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Error" }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
